<template>
  <div class="yandex-comments">
    <iframe :src="yandexWidgetSrc" v-if="showWidget"></iframe>
  </div>
</template>

<script>
export default {
  name: 'YandexComments',

  props: {
    target: {},
  },

  data: () => ({
    id: null,
  }),

  methods: {
    setCommentsTarget(target) {
      this.id = target.id;
    },
  },

  computed: {
    yandexWidgetSrc() {
      if (this.target) {
        return `https://yandex.ru/maps-reviews-widget/${this.target.id}?comments`;
      }
      if (this.id) {
        return `https://yandex.ru/maps-reviews-widget/${this.id}?comments`;
      }
      return '';
    },
    showWidget() {
      return this.id || (this.target && this.target.id);
    },
  },

  mounted() {
    this.$emit('mounted');
  },
};
</script>

<style lang="scss" scoped>
.yandex-comments {
  min-width: 480px;
  max-width: 100%;
  min-height: 640px;
  height: 64vh;

  iframe {
    width: 100%;
    height: 100%;
    border: 1px solid #e6e6e6;
    border-radius: 8px;
    box-sizing: border-box;
  }
}

@media (max-width: 1109px) {
  .yandex-comments {
    min-width: auto;
    min-height: auto;
  }
}
</style>
